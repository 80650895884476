<!-- 道路测速 -- 设备页 -->
<template>
  <div id="velocityMeasurement-equipment" v-loading="loading" :element-loading-text="$t('velocityMeasurement.a1')">
    <!-- 页面标题 -->
    <my-PageTitle>{{ $t('velocityMeasurement.a3') }}</my-PageTitle>
    <!-- 内容区域 -->
    <div class="main">
      <div class="card_container">
        <!-- 设备卡片 -->
        <div class="card" v-for="(item,index) in cardList" :key="index" @click="openDetails(item.deviceCode)">
          <div>
            <img src="../../../public/images/velocityMeasurement/offLine.png" v-show="item.isOnline == 0" />
            <img src="../../../public/images/velocityMeasurement/onLine.png" v-show="item.isOnline == 1" />
          </div>
          <div class="card-title" :title="item.channelName">
            <span>{{ item.channelName }}</span>
          </div>
        </div>
        <div class="empty" v-for="n in 10" :key="'m' + n"></div>
      </div>
    </div>

    <!-- 设备详情对话框 -->
    <a-modal :title="$t('velocityMeasurement.a4')" :width="500" centered v-model="visible" :destroyOnClose="true" @cancel="detailsCancel" v-drag>
      <template slot="footer">
        <a-button key="back" @click="detailsCancel">{{ $t('velocityMeasurement.a5') }}</a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('doortimetable.a1')">
        <a-table :columns="columns" :data-source="detailsData" :pagination="false"/>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { getDeviceList, getDeviceDetails } from '../../api/velocityMeasurement'
import PageTitle from '../../components/Title/PageTitle.vue'

export default {
  components:{
    'my-PageTitle': PageTitle
  },
  data(){
    return{
      visible: false,
      nameVisible:false,
      disabled: false,
      loading: false,
      form:{
        name:''
      },
      cardList:[],
      detailsData: [],
      columns: [
        {
          title: this.$t('velocityMeasurement.a6'),
          dataIndex: 'deviceName',
        },
        {
          title: 'IP',
          dataIndex: 'deviceIp',
        }
      ]
    }
  },
  // 获取测速点（设备）列表
  mounted(){
    this.loading = true
    const data = { pageNum: 1, pageSize: 1000 }
    getDeviceList(data)
    .then(res=>{
      // console.log(res)
      const { data: { pageData } } = res
      this.cardList =  pageData
      this.loading = false
    })
    .catch(err=>{
      console.log(err)
      this.loading = false
    })
  },
  methods:{
    // 获取测速点（设备）详情
    openDetails(deviceCode){
      this.visible = true
      getDeviceDetails(deviceCode)
      .then(res=>{
        // console.log(res)
        const { data } = res
        this.detailsData = [data]
      })
      .catch(err=>{
        console.log(err)
      })
    },
    detailsCancel(){
      this.visible = false
    }
  }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#velocityMeasurement-equipment{
  width: 100%;
  height: 100%;
}
.main{
  width: 100%;
  height: calc(100% - 50px);
}
.card_container {
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 15px 20px 20px;
  overflow: auto;
}
.card {
  display: flex;
  width: 160px;
  height: 85px;
  padding: 10px 10px 10px 0px;
  font-size: 14px;
  background-color: #f2f4f7;
  border: 1px solid #f2f4f7;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 15px;
  transition: 200ms;
}
.card:hover {
  border: 1px solid #7682ce;
}
.card img{
  width: 48px;
  height: 48px;
}
.card-title{
  color: rgba(12, 12, 12,0.85);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow:ellipsis;
  overflow:hidden;
}
.empty {
  width: 180px;
}
</style>